// -----------------------------------//
// HP SLIDER
// -----------------------------------//

.body_7980 #slider {
  position: relative;
  height: 110vh;
  max-height: 1080px;
  min-height: 550px;

  &:before {
    @include before(100%, 5px);
    background: #fff;
    top: 0;
    left: 0;
  }
}

// -----------------------------------//
// IP SLIDER
// -----------------------------------//

#slider {
  width: 100%;
  position: relative;
  min-height: 450px;
  z-index: 1;
}

.backstretch {
  position: relative;

  // for top of page gradient
  &:before {
    @include before(100%, 509px);
    background: url(/i/design/gradient.png) repeat-x top left;
    top: 0;
    left: 0;
  }

  &:after {
    @include before(100%, 558px);
    background: url(/i/design/gradient-btm.png) repeat-x top left;
    bottom: 0;
    left: 0;
  }

  img {
    top: 0px !important;
  }
}

// -----------------------------------//
// STRAPLINE
// -----------------------------------//

.strapline {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 100px;
  margin: 0 auto;
  text-align: center;
  color: #fff;
  font-size: 50px;

  @media only screen and (max-width: 990px) {}

  .bx-cont {
    margin: 0 auto;
    color: #fff;
 

    ul#bxslider {

      li {
        padding: 0;
        background: none;
        text-align: right;
        margin: 0 auto;
        font-size: 10vw;
        font-family: 'Museo 700', Courier Bold, Courier, 'Times New Roman', serif;
        color: black;
        -webkit-text-fill-color: rgba(0, 0, 0, 0.5);
        -webkit-text-stroke-width: 2px;
        -webkit-text-stroke-color: #fff;
        padding: 0px 90px 0 0px;
        display: none;

        @media only screen and (max-width: 990px) {
          padding: 0;
          text-align: center;
        }

        @media only screen and (max-width: 800px) {
          -webkit-text-stroke-width: 1px;
          font-size: 11vw;
        }

        @media only screen and (max-width: 450px) {
          font-size: 14vw;
        }
      }
    }
  }
}

#good-shepherd-trust {
  position: absolute;
  top: 30px;
  left: 30px;

  @media only screen and (max-width: 990px) {
    top: 50px;
    left: 10px;
  }

  @media only screen and (max-width: 690px) {
    left: 0;
    right: 0;
    top: 300px;
  }

  img {
    @media only screen and (max-width: 690px) {
      margin: 0 auto;
    }
  }
}