// &:before { @include before(100px, 100px); background: url(/i/design/) no-repeat center; top: 0; left: 0; } 

// -----------------------------------//
// FONTS
// -----------------------------------//



// -----------------------------------//
// VARIABLES
// -----------------------------------//

$link-color: deepskyblue;

$h1-color: #327472;
$h2-color: lighten(#327472, 10%);
$h3-bg-color: #327472;
$h4-bg-color: lighten(#327472, 15%);
$main-colour: #327472;

// -----------------------------------//
// BODY
// -----------------------------------//

body {
	text-align: center;
	padding: 0;
	margin: 0;
	position: relative;
	overflow-x: hidden;
	font-family: 'Museo 500', Courier Bold, Courier, 'Times New Roman', serif;
}

.body_7980 {}

// -----------------------------------//
// GLOBAL
// -----------------------------------//

.body_7980 * {
	box-sizing: border-box;
}

body a {
	color: $link-color;
	text-decoration: none;
	transition: .5s;
}

body a:hover,
body a:focus {
	text-decoration: none;
}

a img,
img a,
img,
p a img {
	border: 0;
	border-bottom: 0;
	text-decoration: none;
	vertical-align: middle;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: 500;
	font-family: 'Museo 700', Courier Bold, Courier, 'Times New Roman', serif;
}

h1 {
	font-size: 52px;
	margin: 0 0 20px 0;
	color: $h1-color;
	line-height: 1.2;
}

h2 {
	font-size: 35px;
	margin: 25px 0 20px 0;
	color: $h2-color;
	line-height: 1.3;
}

h3 {
	font-size: 20px;
	margin: 25px 0 10px 0;
	padding: 5px 10px 3px 10px;
	background: $h3-bg-color;
	color: #fff;
}

h4 {
	@extend h3;
	background-position: 10px center;
	background-repeat: no-repeat;
	padding-left: 30px;
	background-color: $h4-bg-color;
}

h4.open {
	background-image: url(/i/design/expanding-faq/minus-white.png);
}

h4.closed {
	background-image: url(/i/design/expanding-faq/plus-white.png);
}

h5 {
	font-size: 12px;
}

h6,
small {
	font-size: 10px;
}

h1+h2 {
	margin-top: 0;
	border: 0;
	padding: 0;
}

h1+p,
h2+p,
h3+p {
	margin-top: 0;
}

p {
	margin: 12px 0;
	padding: 0;
}

li {
	margin: 2px 0;
	padding: 0 0 0 23px;
	background: url(/i/icons/bullet_black.png) no-repeat top 6px left 3px;
}

hr {
	height: 1px;
	border-width: 0;
	color: #f2f2f2;
	background-color: #f2f2f2;
}

// -----------------------------------//
// WRAPPER
// -----------------------------------//

#wrapper {
	margin: 0 auto;
	text-align: center;
	max-width: 2000px;
	overflow: hidden;
}

.wrapper {
	width: 90%;
	margin: 0 auto;
	height: 100%;
	@extend .clearfix;
	position: relative;
}

.wrapper-inside {
	width: 1500px;
	max-width: 90%;
	margin: 0 auto;
	@extend .clearfix;
	position: relative;
}

// fix home page wrapper called in t.asp
.body_7980 .wrapper-inside {
	width: 100%;
	max-width: 100%;
	padding: 0;
}

// -----------------------------------//
// HEADER
// -----------------------------------//

header {
	padding: 0;
}

header>.wrapper {
	padding: 0;
	position: relative;
	z-index: 60;
}

header h1 {
	margin: 0;
	background: none;
}

#logo-index {
	position: absolute;
	top: 50px;
	left: 0;
	margin: 0;
	padding: 0;

	@media only screen and (max-width: 1260px) {
		top: 90px;
	}

	@media only screen and (max-width: 990px) {
		left: 0;
		right: 0;
		top: 40px;
	}

	@media only screen and (max-width: 400px) {
		top: 70px;
	}

	@media only screen and (max-width: 320px) {
		top: 80px;
	}

	a {
		span {}

		img#logo {
			@media only screen and (max-width: 990px) {
				margin: 0 auto;

			}
		}
	}
}

// -----------------------------------//
// SOCIAL DOCK
// -----------------------------------//

#social-dock {
	position: absolute;
	right: 0px;
	top: 110px;
	z-index: 99;

	@media only screen and (max-width: 990px) {
		top: 55px;
		left: 0;
		width: fit-content;
		margin: 0 auto;
	}

	li {
		display: inline-block;
		@include box(35px);
		padding: 0;
		background: none;
		margin: 0 2px;
		transition: 0.5s;

		&:hover {
			translate: 0 -2px;
		}

		&:nth-last-of-type(1) {
			@include box(76px, 28px);
		}

		&:nth-last-of-type(2) {
			@include box(71px, 33px);
			margin: 0 12px;
		}

		a {
			display: block;
			@include box(100%);
		}

		#facebook-head {
			@include box(35px);
			overflow: hidden;
			z-index: 99;
			background: url(/i/design/facebook.png) no-repeat center;
		}

		#instagram-head {
			@include box(35px);
			overflow: hidden;
			z-index: 99;
			background: url(/i/design/instagram.png) no-repeat center;
		}

		#arbor-head {
			@include box(76px, 28px);
			overflow: hidden;
			z-index: 99;
			background: url(/i/design/arbor.png) no-repeat center;
		}

		#scopay-head {
			@include box(71px, 33px);
			overflow: hidden;
			z-index: 99;
			background: url(/i/design/scopay.png) no-repeat center;
		}
	}
}

// -----------------------------------//
// GOOGLE TRANSLATE WIDGET
// -----------------------------------//

#google_translate_element {
	opacity: 0;
}

.translate {
	@include box(35px);
	overflow: hidden;
	background: url(/i/design/translate.png) no-repeat center;
	z-index: 99;
}

.goog-te-gadget .goog-te-combo {
	padding: 10px;
}

// -----------------------------------//
// SEARCH FORM
// -----------------------------------//

a#FS {
	@include box(35px);
	background: url(/i/design/search.png) no-repeat center;
	padding: 0px;
	transition: all .3s ease;
	cursor: pointer;
}

/* Search Style */
#search {
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.9);
	transition: all 0.5s ease-in-out;
	transform: translate(0px, -100%) scale(0, 0);
	opacity: 0;
	display: none;
}

#search.open {
	transform: translate(0px, 0px) scale(1, 1);
	opacity: 1;
	z-index: 9999999;
	display: block;
}

#search input[type="search"] {
	position: absolute;
	top: 50%;
	left: 0;
	margin-top: -51px;
	width: 60%;
	margin-left: 20%;
	color: rgb(255, 255, 255);
	background: transparent;
	border-left: 0px solid transparent;
	border-right: 0px solid transparent;
	font-size: 40px;
	font-weight: 300;
	text-align: center;
	outline: none;
	padding: 10px;
}

// -----------------------------------//
// SEARCH RESULTS
// -----------------------------------//

$search-highlight-background-color: $main-colour;
$search-highlight-color: #fff;
$search-highlight-font-weight: bold;
$search-highlight-font-size: 15px;
$search-odd-tr-background: #ececec;

.search-subject-box {
	display: inline-block;
	background: #f1f1f1;
	padding: 5px 15px;
	font-size: 16px;
	border-radius: 4px;
	border: 1px solid #ccc;
	text-transform: uppercase;
}

.search-numbers {
	text-transform: uppercase;
	font-weight: bold;
	font-size: 12px;

	span {
		background: #f1f1f1;
		padding: 3px 8px;
		margin: 0 3px;
		border-radius: 2px;
		border: 1px solid #ccc;
	}
}

// -----------------------------------//
// MAIN HOLDER
// -----------------------------------//

#mainholder {
	width: 100%;
	margin: 0px auto;
	padding: 60px 0 30px 0;
	clear: both;
	text-align: left;
	@extend .clearfix;
	position: relative;
	z-index: 10;
}

// home page alternate
.body_7980 #mainholder {
	padding: 0;
	margin: 0;
}

// -----------------------------------//
// HOME BTNS
// -----------------------------------//

#home-btns {
	padding: 60px 0;
	background: none;
	width: 100%;
	position: absolute;
	z-index: 10;

	@media only screen and (max-width: 990px) {
		position: relative;
		padding: 60px 0 0 0;
	}

	ul.wrapper {
		position: relative;
		display: flex;
		justify-content: center;
		width: 87%;
		width: 1660px;

		@media only screen and (max-width: 1700px) {
			width: 98%;
			width: 1440px;
		}

		@media only screen and (max-width: 1490px) {
			width: 1190px;
		}

		@media only screen and (max-width: 1240px) {
			width: 1060px;
		}

		@media only screen and (max-width: 1090px) {
			width: 950px;
		}

		@media only screen and (max-width: 990px) {
			width: 90%;
			flex-direction: column;
			gap: 20px;
			align-items: center;
		}

		li {
			position: relative;
			background: none;
			padding: 0;
			margin: 0;
			flex-shrink: 0;
			width: 390px;
			transition: 0.2s;
			display: block;
			text-align: center;
			text-align: -webkit-center;

			&:hover {
				letter-spacing: 1px;
				translate: 0 -1px;
				rotate: 1deg;
			}

			@media only screen and (max-width: 1700px) {
				flex-shrink: 0.8;
				width: 350px;
			}

			@media only screen and (max-width: 1490px) {
				width: 300px;
			}

			@media only screen and (max-width: 1240px) {
				width: 270px;
			}

			@media only screen and (max-width: 1090px) {
				width: 245px;
			}

			@media only screen and (max-width: 990px) {
				width: 270px;
			}

			a {
				position: relative;
				text-align: center;
				text-align: -webkit-center;

				img {
					filter: drop-shadow(0px 0px 35px #fff);
					margin: 0 auto;
					position: relative;
				}

				strong {
					position: absolute;
					bottom: 70px;
					display: block;
					// width: 100%;
					font-size: 34px;
					color: #fff;
					z-index: 10;
					font-weight: 600;

					// text-align: center;
					// text-align: -webkit-center;
					// text-align: left;

					// display: flex;
					// justify-content: center;
					// width: fit-content;

					// width: 100%;
					// margin: 0 auto;
					// text-align-last: center;
					// text-align: center;

					// display: grid;
					// justify-items: center;

					position: relative;
					bottom: 120px;
					display: block;

					@media only screen and (max-width: 1240px) {
						font-size: 28px;
					}
				}
			}


			&:nth-of-type(1) {
				margin-right: auto;

				@media only screen and (max-width: 990px) {
					margin-right: 0;
				}

				a {
					img {}
				}
			}

			&:nth-of-type(2) {
				position: absolute;
				top: -170px;
				left: 314px;

				@media only screen and (max-width: 1700px) {
					top: -161px;
					left: 271px;
				}

				@media only screen and (max-width: 1490px) {
					top: -164px;
					left: 220px;
				}

				@media only screen and (max-width: 1240px) {
					top: -154px;
					left: 196px;
				}

				@media only screen and (max-width: 1090px) {
					top: -150px;
					left: 174px;
				}

				@media only screen and (max-width: 990px) {
					top: auto;
					left: auto;
					position: relative;
				}

				a {
					img {}
				}
			}

			&:nth-of-type(3) {

				a {
					img {}
				}
			}

			&:nth-of-type(4) {
				position: absolute;
				top: -170px;
				right: 321px;

				@media only screen and (max-width: 1700px) {
					top: -161px;
					right: 276px;
				}

				@media only screen and (max-width: 1490px) {
					top: -164px;
					right: 227px;
				}

				@media only screen and (max-width: 1240px) {
					top: -154px;
					right: 201px;
				}

				@media only screen and (max-width: 1090px) {
					top: -150px;
					right: 179px;
				}

				@media only screen and (max-width: 990px) {
					top: auto;
					right: auto;
					position: relative;
				}

				a {
					img {}
				}
			}

			&:nth-of-type(5) {
				margin-left: auto;

				@media only screen and (max-width: 990px) {
					margin-left: 0;
				}

				a {
					img {}
				}
			}

		}
	}
}


// -----------------------------------//
// WELCOME SEC
// -----------------------------------//

#welcome-sec {
	padding: 500px 0 100px 0;
	background: #fff url(/i/design/hexagons.png) right no-repeat;
	position: relative;

	@media only screen and (max-width: 990px) {
		padding: 70px 0;
		background: #fff;
	}

	@media only screen and (max-width: 600px) {
		padding: 50px 0;
	}

	&:before {
		@include before(582px, 835px);
		background: url(/i/design/welcome-img.png) no-repeat top left;
		top: 480px;
		left: 0;
		z-index: 99;

		@media only screen and (max-width: 990px) {
			display: none;
		}

		@media only screen and (max-width: 1750px) {
			background: url(/i/design/welcome-img.png) no-repeat top right;
			width: 30vw;
		}

		@media only screen and (max-width: 1440px) {
			width: 25vw;
		}
	}

	.wrapper {
		width: 990px;

		@media only screen and (max-width: 990px) {
			width: 90%;
			margin: 0 auto;
		}

		.inner {
			max-width: 750px;
			margin-left: auto;

			@media only screen and (max-width: 1140px) {
				padding: 0 0 0 60px;
			}

			@media only screen and (max-width: 1050px) {
				padding: 0 24px 0 40px;
			}

			@media only screen and (max-width: 990px) {
				padding: 0;
				max-width: none;
				margin-left: 0;
				text-align: center;
			}

			h2 {
				color: #B3B3B3;
				font-size: 28px;
				letter-spacing: 7px;
				font-family: 'Museo 300', Courier Bold, Courier, 'Times New Roman', serif;

				span {
					display: block;
					color: #000;
					font-family: 'Museo 900', Courier Bold, Courier, 'Times New Roman', serif;
					font-size: 48px;
					letter-spacing: normal;

					@media only screen and (max-width: 600px) {
						font-size: 40px;
					}

					@media only screen and (max-width: 500px) {
						font-size: 32px;
					}
				}
			}

			p {
				font-size: 18px;
				line-height: 33px;

				&:nth-of-type(1) {
					font-size: 28px;
					// line-height: 1.05;
					margin: 30px 0;

					@media only screen and (max-width: 600px) {
						font-size: 23px;
					}


					@media only screen and (max-width: 500px) {
						font-size: 18px;
					}

				}
			}

			.btn-wrap {
				display: flex;
				justify-content: flex-end;
				margin: 60px 0 0 0;

				@media only screen and (max-width: 990px) {
					justify-content: center;
				}

				@media only screen and (max-width: 600px) {
					flex-direction: column;
					align-items: center;
					gap: 15px;
				}

				a.btn {
					height: 60px;
					line-height: 60px;
					text-align: center;
					width: 240px;
					background: #327472;
					border-radius: 30px;
					color: #fff;
					margin: 0 0 0 20px;
					font-size: 18px;
					font-family: 'Museo 900', Courier Bold, Courier, 'Times New Roman', serif;
					text-transform: uppercase;
					letter-spacing: 1px;

					&:nth-of-type(2) {
						background: #EBC814;
					}

					@media only screen and (max-width: 600px) {
						margin: 0;
					}

					&:hover {
						letter-spacing: 2px;
						filter: drop-shadow(0px 1px 2px #000);
					}
				}
			}
		}
	}
}

// -----------------------------------//
// CARE CHALLENGE EXPLORE
// -----------------------------------//

#care-challenge-explore {
	position: relative;
	padding: 0 0 410px 0;

	@media only screen and (max-width: 990px) {
		padding: 70px 0;
		background: #337573 url(/i/design/ashley.jpg) top center;
	}

	@media only screen and (max-width: 600px) {
		padding: 50px 0;
	}


	&:before {
		position: absolute;
		content: "";
		background: #fff url(/i/design/footer.jpg) no-repeat top;
		width: 100%;
		height: 690px;
		left: 0;
		top: 130px;
		pointer-events: none;

		@media only screen and (max-width: 990px) {
			display: none;
		}
	}

	.wrapper {
		&:before {
			position: absolute;
			content: "";
			background: rgba(255, 255, 255, 0.3);
			background: rgba(#fff, 0.3);
			width: 100%;
			height: 100%;
			border-radius: 50px;
			border: 6px solid #fff;
			box-shadow: 0px 16px 18px 0px rgba(0, 0, 0, 0.24);
			pointer-events: none;

			@media only screen and (max-width: 990px) {
				display: none;
			}
		}

		.inner {
			position: relative;
			margin: 0 auto;
			max-width: 750px;
			padding: 0 0 40px 0;

			@media only screen and (max-width: 990px) {
				padding: 0;
			}

			h2 {
				font-size: 57px;
				text-align: center;
				font-family: 'Museo 900', Courier Bold, Courier, 'Times New Roman', serif;
				margin: 0 0 120px 0;

				@media only screen and (max-width: 990px) {
					margin: 0 0 50px 0;
				}

				@media only screen and (max-width: 600px) {
					font-size: 40px;
				}

				@media only screen and (max-width: 500px) {
					font-size: 32px;
					margin: 25px 0 20px 0;
				}

				span {
					&:nth-of-type(1) {
						color: #327472;

						@media only screen and (max-width: 990px) {
							color: #fff;
						}
					}

					&:nth-of-type(2) {
						color: #EBC814;
					}

					&:nth-of-type(3) {
						color: #E1E1E1;
					}
				}
			}

			p {
				font-size: 18px;
				line-height: 28px;
				text-align: center;
				font-family: 'Museo 900', Courier Bold, Courier, 'Times New Roman', serif;
				color: #fff;
			}
		}
	}
}

// -----------------------------------//
// CONTENT COLUMNS
// -----------------------------------//

//Content 1
.Content1Column {
	width: 100%;
	max-width: 1300px;
	margin: 0 auto 50px;
	padding: 30px 50px;
	min-height: 350px;
	text-align: left;
	background: rgba(255, 255, 255, .9);
	@extend .clearfix;

	img {
		max-width: 100%;
		height: auto;
	}
}

//Content 2
.Content2Column {
	width: calc(92% - 350px);
	margin: 0 0 50px 8%;
	padding: 30px 50px;
	min-height: 350px;
	text-align: left;
	display: inline-block;
	float: left;
	background: rgba(255, 255, 255, .9);
	@extend .clearfix;

	img {
		max-width: 100%;
		height: auto;
	}
}

//Content 3
.Content3Column {
	width: 430px;
	margin: 0;
	padding: 0;
	float: left;
	min-height: 350px;
	text-align: left;

	img {
		max-width: 100%;
		height: auto;
	}
}

#mainholder {

	tr,
	td {
		border: 1px solid #BFBFBF;
	}

	td {
		padding: 5px;
	}

	.tablenoborderpadded {
		border: 0 !important;

		tr,
		td {
			border: 0px !important;
		}
	}
}

.HomeAlert .Content1Column {
	float: none;
	margin: 0 auto 50px;
}

// -----------------------------------//
// COLUMN LEFT & COLUMN RIGHT
// -----------------------------------//

.ColumnLeft {
	width: 250px;
	margin: 0;
	padding: 0;
	min-height: 250px;
	text-align: left;
	float: left;
}

.ColumnRight {
	width: 300px;
	margin: 0 20px 0 0;
	padding: 0;
	min-height: 250px;
	text-align: left;
	display: inline-block;
	float: right;
	box-sizing: border-box;
}

.ColumnLeft ul,
.ColumnRight ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

.ColumnLeft nav ul a:link,
.ColumnRight nav ul a:visited,
.ColumnRight nav ul a:link,
.ColumnRight nav ul a:visited {
	display: block;
}

.ColumnLeft li {
	border-bottom: 1px solid #EEE;
}

// Hack to fix IE/Win's broken rendering of block-level anchors in lists
html>body .ColumnLeft li {
	border-bottom: none;
}

// Fix for browsers that don't need the hack

// -----------------------------------//
// SIDE COLUMN > LIST SECTIONS
// -----------------------------------//

// Container
.SideHeading {
	margin: 0;
	padding: 0;
	text-align: center;
}

// List
ul.SideList {
	margin: 0;
	padding: 0;
	border: 0;
	list-style: none;
	text-align: left;
}

ul.SideList li {
	margin: 0;
	padding: 0;
	background: none;
}

ul.SideList li a {
	margin: 0;
	padding: 0px;
	color: #000;
	transition: .5s;
	font-weight: normal;
	display: block;
}

ul.SideList li a:hover {
	transition: .5s;
	text-decoration: none;
}

ul.SideList li a.child {
	background: url(/i/design/svg/ic-related-pages.svg) left 20px center no-repeat;
	padding-left: 40px !important;
}

// Date and Title
ul.SideList li p {
	margin: 0;
	padding: 0;
}

ul.SideList p.date {
	float: left;
	text-align: center;
	font-size: 20px;
	line-height: 130%;
	transition: .5s;

	// Span = number
	span {
		display: block;
		transition: .5s;
	}
}

ul.SideList p.title {
	float: right;
	width: 100%;
	font-size: 16px;
	transition: .5s;

	// Truncate
	span {
		overflow: hidden;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		height: 35px;
	}
}

// More links
.SideHeading a.more {
	display: inline-block;
	font-size: 18px;
	padding: 0px;
	transition: .5s;

	&:hover {
		transition: .5s;
		text-decoration: none;
	}
}

// -----------------------------------//
// DIARY
// -----------------------------------//

//Diary
#SideHeadingDiary {

	ul.SideList {

		li {

			p.date {
				span {}
			}

			p.title {}
		}
	}
}

// -----------------------------------//
// NEWS
// -----------------------------------//

//News
#SideHeadingNews {

	ul.SideList {

		li {

			p.date {
				span {}
			}

			p.title {}
		}
	}
}

// -----------------------------------//
// RELATED PAGES
// -----------------------------------//

#SideHeadingPages {
	position: relative;
	overflow: hidden;
	z-index: 1;
	padding: 5%;
	background-color: #327472;
	border-radius: 15px;

	h2 {
		display: block;
		text-align: center;
		font-size: 28px;
		padding: 20px 0;
		margin: 0;
		color: #fff;
	}

	ul.SideList {
		padding: 0 0 30px 0;

		li {
			a {
				padding: 7px 20px;
				font-size: 18px;
				color: #fff;

				&:hover {
					background-color: rgba(255, 255, 255, .1);
				}
			}
		}
	}
}

// -----------------------------------//
// HOME PAGE ALERT
// -----------------------------------//

//Modal
.modal-home {
	background: rgba(0, 0, 0, .4);
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 8000;

	.message-modal {
		position: absolute;
		top: calc(50% - 300px);
		left: calc(50% - 465px - 40px - 10px);
		width: 930px;
		height: auto;
		margin: 0 30px;
		padding: 20px;
		background: rgba(255, 255, 255, .9);

		.modal-content {
			background: rgba(255, 255, 255, .9);
			background-position: -120px 50px;
			box-shadow: inset 0 0 0 1px #fff;
			display: inline-block;
			width: 100%;
			height: 100%;
			text-align: center;

			.modal-text {
				display: block;
				padding: 45px;

				p {
					margin: 0;
				}
			}

			a.close-modal {
				position: absolute;
				top: 31px;
				right: 31px;
			}
		}
	}
}

.hide-modal {
	display: none !important;
}

// -----------------------------------//
// AWARDS
// -----------------------------------//

#awards {
	width: 100%;
	overflow: hidden;
	background: #fff;
	padding: 20px 0;

	.CMSawards {
		overflow: hidden;
		width: 100%;
		height: 100%;
		margin: 0 auto !important;
		background: none !important;

		ul {
			margin: 0;

			li {
				background: none;
				padding: 0 5px;
				margin: 0 10px;
				display: inline-block;

				img {
					max-height: 70px;
					transform: none !important;
				}
			}
		}
	}
}

// -----------------------------------//
// SEASONAL FX
// -----------------------------------//

.seasonaleffect {
	z-index: 999 !important;
}

// -----------------------------------//
// FOOTER
// -----------------------------------//

footer {
	margin: 0 auto;
	clear: both;
	background: #327472 url(/i/design/footer-bg.jpg) no-repeat;
	position: relative;
	padding: 70px 0 20px 0;

	.wrapper {
		text-align: left;
		font-size: 20px;
		width: 990px;
		line-height: 24px;

		@media only screen and (max-width: 1070px) {
			padding: 0 40px;
		}

		@media only screen and (max-width: 990px) {
			padding: 0;
		}

	}

	p,
	a[href^="mailto:"] {
		margin: 0;
		color: #fff;
	}

}

h2.footer-heading {
	padding: 0;
	margin: 0;
	color: #fff;
	font-size: 24px;
	font-family: 'Museo 900', Courier Bold, Courier, 'Times New Roman', serif;

	@media only screen and (max-width: 990px) {
		margin: 0 auto 20px !important;
	}
}

p.address {}

p.telephone {}

footer a[href^="mailto:"] {
	display: block;
	font-family: 'Museo 900', Courier Bold, Courier, 'Times New Roman', serif;
	color: #EBC814;

	&:hover {
		text-decoration: underline;
	}
}

.map {
	position: absolute;
	z-index: 99;
	// left: 970px;
	right: 400px;
	top: -280px;
	transition: 0.4s;

	@media only screen and (max-width: 1630px) {
		right: 100px;
	}

	@media only screen and (max-width: 1200px) {
		scale: 0.7;
		right: 0;
	}

	@media only screen and (max-width: 990px) {
		display: none;
	}

	&:hover {
		&:before {
			scale: 1.02;
		}
	}

	&:before {
		position: absolute;
		content: "";
		background: url(/i/design/map-marker.png) center center no-repeat;
		top: 55%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 368px;
		height: 368px;
		transition: 0.4s;
		z-index: 10;
	}

	img {
		filter: drop-shadow(0px 5px 13px rgba(#000, 0.3));
	}
}

// -----------------------------------//
// SUB-FOOTER
// -----------------------------------//

.sub-footer {
	// height: 30px;
	// line-height: 30px;
	overflow: hidden;
	@extend .clearfix;
	padding: 40px 0 0 0;

	p {
		font-size: 12px;
		color: #fff;
		float: left;
		margin: 0;
	}

	a {
		color: #fff;

		&:hover {
			text-decoration: underline;
		}
	}

	#copyright,
	#credit {
		a {
			color: #fff;
		}
	}

	#copyright {
		float: left;
	}

	#credit {
		float: right;

		img {
			display: inline-block;
			margin: -10px 0 0 5px;
		}
	}
}

// -----------------------------------//
// TOP BUTTON
// -----------------------------------//

.top-btn {
	position: fixed;
	z-index: 99999;
	bottom: 50px;
	right: 30px;
	display: block;
	width: 45px;
	height: 45px;
	background: rgba(0, 0, 0, .3);
	border-radius: 50%;
	color: #fff;
	font-size: 25px;
	text-align: center;
	opacity: 0;
	transform: scale(0);
	transition: .5s cubic-bezier(0.68, -0.55, 0.265, 1.55);

	@media only screen and (max-width: 1199px) {
		display: none;
	}
}

.show-top-btn {
	opacity: 1;
	transform: scale(1);
}

// -----------------------------------//
// CMS CONTENT: TABLES
// -----------------------------------//

$table-highlight-color: darkblue;
$table-highlight-background-color: lightblue;
$table-highlight-font-weight: bold;
$table-border-color: #222;

// -----------------------------------//
// CMS CONTENT: BLOCKQUOTES
// -----------------------------------//

blockquote p {
	text-align: center;
}

$blockquote-background-color: #fff;
$blockquote-border-color: #eeeeee;
$blockquote-color: #222;
$blockquote-speech-mark-color: #222;
$blockquote-font-size: 1em;